import React, { useState, useEffect } from "react";
import Demo from "./Demo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SendMail = () => {
  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cc, setCc] = useState("");
  const [company, setCompany] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!name) {
      setError("Name is required");
    } else if (!email) {
      setError("Email is required");
    } else if (!company) {
      setError("Company is required");
    } else {
      setError("");
      console.log("submit");

      try {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/send-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subject,
            name,
            email,
            cc,
            company,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log("data", data);
            if (data.status === "success") {
              setSuccess(data.message);
              setSubject("");
              setName("");
              setEmail("");
              setCc("");
              setCompany("");
              setLoading(false);
              toast.success(data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              setError(data.message);
              setLoading(false);
              toast.error(data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          })
          .catch((err) => {
            console.log("err", err.message);
            setError("Something went wrong");
            setLoading(false);
            toast.error(err.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          });
      } catch (error) {
        console.log("error", error.message);
        setError("Something went wrong");
        setLoading(false);
        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  useEffect(() => {
    setError("");
    setSuccess("");
  }, [name, email, company]);

  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      <aside
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form onSubmit={onSubmit}>
          <h1 className="loginText">Send Mail</h1>
          <input
            type="text"
            placeholder="Subject (optional)"
            className="input"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <br />
          <input
            type="text"
            placeholder="Name"
            className="input"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <br />
          <input
            type="email"
            placeholder="Email"
            className="input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <input
            type="text"
            placeholder="CC (optional)"
            className="input"
            value={cc}
            onChange={(e) => setCc(e.target.value)}
          />
          <br />
          <input
            type="company"
            placeholder="Company"
            className="input"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <br />
          {error.length > 0 && <p className="errorText">{error}</p>}
          {success.length > 0 && <p className="successText">{success}</p>}
          <br />
          <button type="submit" className="btn" disabled={loading}>
            {loading ? "loading..." : "Send"}
          </button>
        </form>
      </aside>

      <aside style={{ width: "50%" }}>
        {/* <Demo name={name} company={company} /> */}
      </aside>

      <ToastContainer />
    </div>
  );
};

export default SendMail;
