import React, { useState, useEffect } from "react";
import SendMail from "./components/SendMail";
import { ToastContainer, toast } from "react-toastify";

function App() {
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const [match, setMatch] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("submit");

    if (pass === process.env.REACT_APP_PASSWORD) {
      try {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/`)
          .then((res) => res.json())
          .then((data) => {
            setMatch(true);
            setError("");
          })
          .catch((err) => {
            console.log("err", err.message);
            setError("Something went wrong");
            setLoading(false);
          });
      } catch (error) {
        console.log("error", error.message);
        setError("Something went wrong");
        setLoading(false);
      }
    } else {
      setMatch(false);
      setError("Password didn't match");
    }
  };

  useEffect(() => {
    setError("");
  }, [pass]);

  return (
    <div>
      {match ? (
        <SendMail />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <form onSubmit={onSubmit}>
            <h1 className="loginText">Login</h1>
            <input
              type="password"
              placeholder="password"
              className="input"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
            <br />
            {error && <p className="errorText">{error}</p>}
            <button type="submit" className="btn">
              {loading ? "loading..." : "Submit"}
            </button>
          </form>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default App;
